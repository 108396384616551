<div class="parent-container" [ngClass]="{'compact': compact,  'vmfest-font' : isVMFest}">

    <div class="top-container">
        <div class="search-container">
            <input type="text" #searchInput (focus)="onSearchFocusAndBlur()" (blur)="onSearchFocusAndBlur()"
                [formControl]="searchControl" />
            <button class="clear" *ngIf="searchInput.value?.length > 0" (click)="clear()" mat-icon-button>
                <mat-icon fxHide.lt-md fxShow.gt-sm>clear</mat-icon>
                <mat-icon fxHide.gt-sm fxShow.lt-md>highlight_off</mat-icon>
            </button>
        </div>
        <app-image-carrousel *ngIf="!compact" [images]="images"></app-image-carrousel>
    </div>

    <div id="top" class="middle-container" *ngIf="!compact && superCategories?.length > 0" fxLayout="row wrap"
        fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.lt-md="15px">
        <a routerLinkActive="active-link" [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', ('all-events' | translate)]"
            fragment="top">{{'All events' | translate}}</a>
        <a routerLinkActive [ngClass]="{ 'active-link' : rla.isActive || superCategory?.id == category?.id }"
            #rla="routerLinkActive" *ngFor="let category of superCategories"
            [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', category.slug]" fragment="top">{{category.name}}</a>
        <a routerLinkActive="active-link" [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', ('other' | translate)]"
            fragment="top">{{'Other' | translate}}</a>
        <!-- <a routerLinkActive="active-link" *ngIf="!isVMFest" routerLink="/vm-fest"
            fragment="top">{{'VM Fest 2025' | translate}}</a> -->
    </div>

    <div *ngIf="featuredEvents?.length > 0">
        <div class="bottom-container" >
            <div fxLayout="row" fxLayoutAlign="start start">
                <h2 *ngIf="!isVMFest">{{'Tips for things to do in Trondheim' | translate}}:</h2>
                <h2 *ngIf="isVMFest">{{'Featured events' | translate}}:</h2>
            </div>
            <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between start"
                fxLayoutAlign.lt-md="start start">
                <app-event-tile fxFlex="32%" fxFlex.lt-md="100%" *ngFor="let event of featuredEvents"
                    [event]="event" [isVMFest]="isVMFest"></app-event-tile>
            </div>
            <div *ngIf="isVMFest" class="disclaimer">{{'Disclaimer text here' | translate}}</div>
        </div>
    </div>


    <div class="bottom-container-space" *ngIf="featuredEvents?.length > 0">
        <h2 *ngIf="featuredEvents?.length > 0 && !isVMFest">{{'Check out all the events in Trondheim' | translate}}:</h2>
        <h2 *ngIf="featuredEvents?.length > 0 && isVMFest">{{'Check out all the VM Fest events' | translate}}:</h2>
    </div>

    <div [ngClass]="featuredEvents?.length > 0 ? 'events' : 'bottom-container'">
        <app-frontpage-events  [isVMFest]="isVMFest"></app-frontpage-events>
    </div>
</div>