import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageObject } from '../models/image-object';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../services/utils.service';
import { Category } from '../models/category';
import { CategoryService } from '../services/category.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventListService } from '../services/event-list.service';
import { Filter } from '../models/filter';
import { EventService } from '../services/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss']
})
export class FrontpageComponent implements OnInit, OnDestroy {

  images: ImageObject[] =
    [{
      urlLarge: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Ffrontpage_trdevents.jpeg?alt=media&token=f1b203d5-f6e0-4401-9f60-01c22f8be19a',
      urlSmall: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Ffrontpage_trdevents.jpeg?alt=media&token=f1b203d5-f6e0-4401-9f60-01c22f8be19a',
      urlOriginal: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Ffrontpage_trdevents.jpeg?alt=media&token=f1b203d5-f6e0-4401-9f60-01c22f8be19a'
    }];

  superCategories: Category[];
  searchControl: FormControl = new FormControl();
  environment: any;
  sub: Subscription;
  filterURLChange: Subscription;
  compact: boolean = false;
  superCategory: Category;
  featuredEvents: Event[];
  isVMFest: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private eventListService: EventListService,
    private eventService: EventService,
    private categoryService: CategoryService,
    public utilsService: UtilsService) { }

  ngOnInit(): void {
    this.environment = environment;
    this.setupURLChangeListener();
    //Setup categories
    this.setupCategories();
    //Setup search control
    this.setupSearchControl();
    //On language change
    this.translate.onLangChange.subscribe(() => this.setupCategories());
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.filterURLChange?.unsubscribe();
  }

  setupCategories() {
    this.superCategories = this.categoryService.getAllCategories().filter(c => c.id != 'OTHER');
  }

  setupSearchControl() {
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(
      (newValue: string) => {
        if (newValue) {
          this.router.navigate(['/', "search", newValue], { replaceUrl: true });
        } else {
          this.router.navigate(['/']);
        }
      }
    )
  }

  maybeSetupVMFestImage() {
    this.images =
    [{
      urlLarge: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Fvmfestfrontpage.jpg?alt=media&token=959940ee-be76-4cf7-9858-06ecc4252613',
      urlSmall: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Fvmfestfrontpage.jpg?alt=media&token=959940ee-be76-4cf7-9858-06ecc4252613',
      urlOriginal: 'https://firebasestorage.googleapis.com/v0/b/trdevents-224613.appspot.com/o/events%2Ffrontpage%2Fvmfestfrontpage.jpg?alt=media&token=959940ee-be76-4cf7-9858-06ecc4252613'
    }];
  }

  setupURLChangeListener() {
    this.filterURLChange = this.route.params.subscribe(
      (params) => {
        this.isVMFest = this.route.snapshot.data?.custom === 'vm-fest';
        if (this.isVMFest) {
          //VM FEST
          this.maybeSetupVMFestImage();
        }
        if (params['searchTerm']?.length > 0) {
          const filter = new Filter();
          filter.searchTerm = params['searchTerm'];
          this.searchControl.setValue(params['searchTerm']);
          this.eventListService.filterEvents(filter);
          this.compact = this.searchControl.value?.length > 0;
        }
        this.superCategory = this.categoryService.getSuperCategoryBySlug(params.categories);
        if (Object.keys(params).length == 0) {
          const onlyFeatured = new Filter();
          onlyFeatured.onlyFeatured = true;
          onlyFeatured.onlyVMFest = this.isVMFest || false;
          const fSub = this.eventService.getEvents(onlyFeatured).subscribe(
            (data: any) => {
              fSub?.unsubscribe();
              this.featuredEvents = data?.data?.events?.data || [];
            }
          );
        } else {
          this.featuredEvents = [];
        }
      });
  }

  onSearchFocusAndBlur() {
    this.compact = this.searchControl.value?.length > 0;
  }

  clear() {
    this.searchControl.setValue(null);
  }

}
